const ExperienceStatus = {
  PENDING: 0,
  IN_PROGRESS: 1,
  CANCELLED: -1,
  REGISTERED: 10,
};

const ExperienceCandidateSource = {
  UNKNOWN: 0,
  OWN: 1,
  SEARCH_PROFILE_RECOMMENDATIONS: 2,
  SEARCH_BAR: 3,
  SCHOOL_DIRECTORY: 4,
  NEW_EXPERIENCE_EMAIL: 5,
  ANNUAL_EMAIL: 6,
};

export default {
  ExperienceStatus,
  ExperienceCandidateSource,
};
