import pages from '../routes/paths';
import ExperienceConstants from './experiences';

const { ExperienceCandidateSource } = ExperienceConstants;

const AppComponents = {
  LANDING: {
    name: 'landing',
    pathRegex: /^\/$/,
  },
  SEARCH_PROFILE_RECOMMENDATIONS: {
    name: 'search_profile_recommendations',
    pathRegex: RegExp(`^${pages.EXPERIENCE_RECOMMENDATIONS}$`),
    candidateSource: ExperienceCandidateSource.SEARCH_PROFILE_RECOMMENDATIONS,
  },
  EXPERIENCE_CANDIDATES: {
    name: 'experience_candidates',
    pathRegex: RegExp(`^${pages.EXPERIENCE_CANDIDATES}$`),
  },
  OWN_CANDIDATES: {
    name: 'own_candidates',
    pathRegex: RegExp(`^${pages.EXPERIENCE_CANDIDATES}$`),
    candidateSource: ExperienceCandidateSource.OWN,
  },
  SEARCH_BAR: {
    name: 'search_bar',
    candidateSource: ExperienceCandidateSource.SEARCH_BAR,
  },
  SCHOOL_DIRECTORY: {
    name: 'school_directory',
    pathRegex: /^\/colegios\/[\w+]\/[\w+]$/,
    candidateSource: ExperienceCandidateSource.SCHOOL_DIRECTORY,
  },
  SCHOOL_PROFILE: {
    name: 'school_profile',
    pathRegex: /^\/school\/[\d+]$/,
  },
  NEW_EXPERIENCE_EMAIL: {
    name: 'new_experience_email',
    candidateSource: ExperienceCandidateSource.NEW_EXPERIENCE_EMAIL,
  },
  ANNUAL_EMAIL: {
    name: 'annual_email',
    candidateSource: ExperienceCandidateSource.ANNUAL_EMAIL,
  },
};

AppComponents.inferFromUrlPath = (path) => {
  const components = Object.values(AppComponents);
  return components.find((component) => component.pathRegex && component.pathRegex.test(path));
};

AppComponents.findByName = (name) => {
  const components = Object.values(AppComponents);
  return components.find((component) => component.name === name);
};

export default AppComponents;
